import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_TESTS: `${API_HOST}admin/tests/all_tests`,
  TEST_STATS: `${API_HOST}front/test/get_test_stats`,
  ADMIN_ADD_TEST: `${API_HOST}admin/tests/create_test`,
  ASSIGN_TEST: `${API_HOST}admin/tests/test_assign_to_user`,
  ADMIN_EDIT_TEST: `${API_HOST}admin/tests/edit_test`,
  ADMIN_DELETE_TEST: `${API_HOST}admin/tests/delete_test`,
  // ADMIN QUESTIONS
  ADMIN_TEST_QUESTIONS: `${API_HOST}admin/tests/test_questions`,
  ADMIN_TEST_ADD_QUESTION: `${API_HOST}admin/tests/add_question`,
  ADMIN_TEST_EDIT_QUESTION: `${API_HOST}admin/tests/edit_question`,
  ADMIN_TEST_DELETE_QUESTION: `${API_HOST}admin/tests/delete_question`,
  ADMIN_TEST_QUESTION_OPTIONS: `${API_HOST}admin/tests/question_options`,
  // ADMIN QUESTION OPTIONS
  ADMIN_TEST_QUESTION_ADD_OPTION: `${API_HOST}admin/tests/add_option`,
  ADMIN_TEST_QUESTION_EDIT_OPTION: `${API_HOST}admin/tests/edit_option`,
  ADMIN_TEST_QUESTION_DELETE_OPTION: `${API_HOST}admin/tests/delete_option`,

  // ADMIN ANSWERS
  ADMIN_TEST_ANSWERS: `${API_HOST}admin/tests/test_results`,
  ADMIN_TEST_ADD_ANSWER: `${API_HOST}admin/tests/add_result`,
  ADMIN_TEST_EDIT_ANSWER: `${API_HOST}admin/tests/edit_result`,
  ADMIN_TEST_DELETE_ANSWER: `${API_HOST}admin/tests/delete_result`,
  ADMIN_TEST_ANSWER_CONTENTS: `${API_HOST}admin/tests/result_content`,
  // ADMIN ANSWER OPTIONS
  ADMIN_TEST_ANSWER_ADD_CONTENT: `${API_HOST}admin/tests/add_result_content`,
  ADMIN_TEST_ANSWER_EDIT_CONTENT: `${API_HOST}admin/tests/edit_result_content`,
  ADMIN_TEST_ANSWER_DELETE_CONTENT: `${API_HOST}admin/tests/delete_result_content`,

  // FRONT TESTS
  FRONT_GET_TESTS:`${API_HOST}front/test/test_types`,
  FRONT_GET_TESTS_DETAILS:`${API_HOST}front/test/test_data`,
  GET_ASSIGNED_TEST: `${API_HOST}front/test/get_assigned_tests`,
  COMPLETE_TESTS: `${API_HOST}front/test/mark_test_complete`,
  DELETE_ASSIGN_TEST: `${API_HOST}front/test/delete_assigned_test`,
  FRONT_GET_TESTS_RESULT:`${API_HOST}front/test/result`

}
