import { API_HOST } from "./config"
export default {
  // ADMIN ROUTES
  ADMIN_APPOINTMENTS: `${API_HOST}admin/video/all_appointments`,
  ADMIN_ADD_APPOINTMENT: `${API_HOST}admin/video/create_appointment`,
  ADMIN_EDIT_APPOINTMENT: `${API_HOST}admin/video/edit_appointment`,
  ADMIN_DELETE_APPOINTMENT: `${API_HOST}admin/video/delete_appointment`,
  // ADMIN CLASSES
  ADMIN_MEETING_CLASSES: `${API_HOST}admin/video/all_classes`,
  ADMIN_MEETING_ADD_CLASS: `${API_HOST}admin/video/create_class`,
  ADMIN_MEETING_EDIT_CLASS: `${API_HOST}admin/video/edit_class`,
  ADMIN_MEETING_DELETE_CLASS: `${API_HOST}admin/video/delete_class`,
  ADMIN_MEETING_CLASS_DETAIL: `${API_HOST}admin/video/class_detail`,
  ADMIN_MEETING_CLASS_SEND_INVITATION: `${API_HOST}admin/video/send_invitation`,
  // ADMIN MEETINGS
  ADMIN_MEETINGS: `${API_HOST}admin/video/all_meetings`,
  ADMIN_ADD_MEETING: `${API_HOST}admin/video/create_meeting`,
  ADMIN_EDIT_MEETING: `${API_HOST}admin/video/edit_meeting`,
  ADMIN_DELETE_MEETING: `${API_HOST}admin/video/delete_meeting`,

  // CLINIC MEETINGS 
  CLIENT_APPOINTMENTS: `${API_HOST}clinics/video/all_appointments`,
  CLIENIC_ADD_APPOINTMENT : `${API_HOST}clinics/video/create_appointment`,
  CLINIC_DELETE_APPOINTMENT : `${API_HOST}clinics/video/delete_appointment`,
  CLIENIC_EDIT_APPOINTMENT: `${API_HOST}clinics/video/edit_appointment`,

  // CLINIC CLASSES 
  CLINIC_MEETING_CLASSES: `${API_HOST}clinics/video/all_classes`,
  CLINIC_ADD_CLASSES: `${API_HOST}clinics/video/create_class`,
  CLINIC_DELETE_CLASSES: `${API_HOST}clinics/video/delete_class`,
  CLINIC_EDIT_CLASSES: `${API_HOST}clinics/video/edit_class`,
  CLINIC_USERS_LISTS: `${API_HOST}clinics/video/clinic_users`,

  // CLINIC LIVE MEETINGS
  CLINIC_LIVE_CLASSES: `${API_HOST}clinics/video/all_meetings`,
  CLINIC_DELETE_LIVE_MEETING: `${API_HOST}clinics/video/delete_meeting`,
  CLINIC_ADD_LIVE_MEETING: `${API_HOST}clinics/video/create_meeting`,
  CLINIC_EDIT_LIVE_MEETING: `${API_HOST}clinics/video/edit_meeting`,

  //CLINIC_PATIENTS
  CLINIC_ALL_PATIENTS: `${API_HOST}clinics/patients/get_pateints`,
  CLINIC_EDIT_PATIENTS: `${API_HOST}clinics/patients/edit_patient`,
  GET_INVOICE: `${API_HOST}admin/payments/get_invoices`,
  NEW_INVOICE: `${API_HOST}admin/payments/new_invoice`,
  GET_PACKAGES_STATUS: `${API_HOST}admin/packages/get_packages_status`,

  ADD_INVOICE: `${API_HOST}admin/payments/add_invoice`,
  PERSONAL_USER_PAYMENTS: `${API_HOST}admin/payments/personal_dev_payments`,
  CLIENT_PAID_SERVICES: `${API_HOST}admin/payments/get_client_payments`,

  CLINIC_EDIT_PATIENTS_SETTINGS: `${API_HOST}clinics/patients/edit_patient_setting`,
  INVITE_USER: `${API_HOST}clinics/patients/invite_clients`,
  APPROVED_BY_USER_PATIENTS: `${API_HOST}admin/users/confirmed_by_user`,
  APPROVED_BY_ADMIN_PATIENTS: `${API_HOST}admin/users/confirmed_by_admin`,
  CLINIC_DELETE_PATIENTS: `${API_HOST}clinics/patients/delete_user_from_clinic`,
  CLINIC_PATIENTS_CHART_DATA: `${API_HOST}clinics/patients/patient_chart`,
  CLINIC_PATIENTS_STATISTICS: `${API_HOST}clinics/patients/patient_stats`,
  CLINIC_PATIENTS_ACTIVITY: `${API_HOST}clinics/patients/patient_activity`,
  CLINIC_FORGET_PASSWORD: `${API_HOST}clinics/patients/send_forget_password_email`,
  
  // CLINIC EMPLOYEES 
  CLINIC_ALL_EMPLOYEES: `${API_HOST}clinics/employees/get_employees`,
  CLINIC_EDIT_EMPLOYEES: `${API_HOST}clinics/employees/edit_employee`,
  CLINIC_EDIT_EMPLOYEES_SETTINGS: `${API_HOST}clinics/employees/edit_employee_setting`,
  CLINIC_ADD_EMPLOYEES: `${API_HOST}clinics/employees/create_employee`,
  CLINIC_DENY_EMPLOYEES: `${API_HOST}front/emp_appointments/deny_requested_appointment`,
  ASSIGN_PATIENTS_TO_EMPLOYEES: `${API_HOST}clinics/patients/assign_patient`,

  // PACKAGES 
  GET_ALL_PACKAGES: `${API_HOST}admin/packages/all_packages`,
  
  // FRONT MEETING
  FRONT_SCHADULE_APPOINTMENTS: `${API_HOST}front/video/get_schedule_appointments`,
  FRONT_REQUESTED_APPOINTMENTS: `${API_HOST}front/video/get_requested_appointments`,
  FRONT_DELETE_APPOINTMENTS: `${API_HOST}front/video/delete_requested_appointment`,
  FRONT_ADD_APPOINTMENT_REQUEST: `${API_HOST}front/video/appointment_request`,
  FRONT_AVAILABILITY_APPOINTMENT: `${API_HOST}front/video/send_appointment_availability`,
  FRONT_EMPLOYEES_LIST: `${API_HOST}front/video/get_employees_for_appointment`,

  // FRONT CLASSES
  FRONT_SCHADULE_CLASSES: `${API_HOST}front/video/get_schedule_classes`,
  FRONT_AVAILABLE_CLASSES: `${API_HOST}front/video/get_available_classes`,
  FRONT_CLASSES_OUT: `${API_HOST}front/video/out_class`,
  FRONT_CLASSES_JOIN: `${API_HOST}front/video/join_class`,

  // FRONT LIVE 
  FRONT_LIVE_SESSION: `${API_HOST}front/video/live_session`,
  FRONT_JOIN_SESSION: `${API_HOST}front/video/join_session`,
  FRONT_OUT_FROM_SESSION: `${API_HOST}front/video/out_from_session`,
  TERM_AND_CONDITIONS: `${API_HOST}front/authentication/get_terms`,
  PRIVACY_POLICY: `${API_HOST}front/authentication/get_privacy_policy`,

  // ------------------------------EMPLOYEE DATA-----------------------------
  EMP_SCHEDULE_APPOINTMENTS: `${API_HOST}front/emp_appointments/get_schedule_appointments`,
  EMP_REQUESTED_APPOINTMENTS: `${API_HOST}front/emp_appointments/get_requested_appointments`,
  EMP_DELETE_SCHEDULE_APPOINTMENTS: `${API_HOST}front/emp_appointments/delete_schedule_appointment`,
  EMP_PATENTS_LIST: `${API_HOST}front/emp_appointments/get_patients`,
  EMP_SEND_APPOINTMENT: `${API_HOST}front/emp_appointments/create_appointment`,
  EMP_UPDATE_APPOINTMENT: `${API_HOST}front/emp_appointments/edit_appointment`,

  // INVITE USER API
  INVITE_USERS: `${API_HOST}front/dashboard/invitation_email`,
  CREATE_PATIENT_USER: `${API_HOST}clinics/patients/create_patient_user`,
  USER_DETAIL: `${API_HOST}clinics/patients/user_detail`
}
